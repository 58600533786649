@import "../../../../App.scss";

.jobOffer {
  @include mobile(){
    row-gap: 16px !important;
  }
  &__image {
    min-height: 314px;
    width: 100%;
    & > img {
      
      width: 100%;
      height: 100%;
    }
  }
  &__content {
    margin-bottom: 34px;
    @include mobile(){
      margin-bottom: 20px;
    }
    &__title{
      @include mobile(){
        margin-bottom: 21px !important;
      }
    }
    &--title{
      font-size: 16px !important;
      // max-width: 444px;
    }
  }
}

.panelJobOffer {
  margin-bottom: 32px;
  position: relative;
  left: -15px;
  @include mobile(){
    left: 0px;
  }
  &__header {
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  &__header.active {
    @extend .panelJobOffer__header;
    color: $mainColor;
  }
}

.fire-emoji {
  display: inline-block;
  animation: flicker 1.5s infinite alternate;

  text-shadow: 0 0 20px rgba(255, 87, 34, 1), 
               0 0 40px rgba(255, 87, 34, 0.8),
               0 0 60px rgba(255, 87, 34, 0.6);
  font-size: 2rem; 
}

@keyframes flicker {
  0% {
    transform: scale(1); 
    opacity: 1;
  }
  50% {
    transform: scale(1.5); 
    opacity: 0.7; 
  }
  100% {
    transform: scale(1); 
    opacity: 1; 
  }
}