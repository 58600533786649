@import "../../../../App.scss";

.headline {
  height: 200px;
  width: 100%;
  display: flex;
  align-items: center;
  .button__custom{
    width: 170px !important;
  }
  padding-left: 281px;
  padding-right: 280px;
  background-color: $mainColor;
  @include mobile {
    padding-left: 15px;
    padding-right: 15px;
    align-items: normal;
  }
  &--title {
    color: #fff !important;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-bottom: 4px;
    @include mobile() {
      font-size: 28px;
    }
  }
  &__content {
    @include mobile {
      margin-top: 30px;
    }
  }
  .title {
    color: $whiteColor;
    margin-bottom: 4px;
    &__content {
      color: $whiteColor;
      font-size: 16px;
    }
  }
  .button {
    justify-content: center;
    display: flex;
    @include mobile {
      float: none;
      justify-content: space-between;
    }
    &__searchJob {
      margin-right: 10px;
    }
    &__custom{
      width: 100%;
    }
  }
}

.jobApplyModal {
  width: 586px;
  height: 606px;
  top: 50px;
  @include mobile() {
    padding: 0 8px;
    top: 100px;
  }
  &__title {
    margin-bottom: 30px;
    text-align: center;
    color: #042d0d;

    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .formApplyJob {
    padding: 26px 56px 41px 56px;
    @include mobile() {
      padding: 0;
    }
    &__label {
      margin-bottom: 12px;
    }
    &__input {
      height: 36px;
      width: 100%;
      .ant-input-group {
        height: 36px;
      }
      .ant-input {
        height: 36px;
      }
    }
    &__upload {
      width: 100%;
    }
    &__submit {
      margin-top: 40px;
      height: 48px;
      width: 100%;
      background-color: $mainColor;
      color: $whiteColor;
    }
    &__support {
      padding-top: 8px;
      color: #bdbdbd;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    &--career {
      display: flex;
      p {
        padding-left: 10px;
      }
    }
  }
  
}

.ant-upload {
  width: 100%;
}
