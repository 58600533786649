
@import "../../App.scss";
.contactPage {
    margin: 0 !important;
    @include mobile(){
        flex-direction: column;
    }
    &__right {
        // width: 100%;
        border-radius: 50px;
        text-align: justify;
        background: rgba(37, 159, 180, 0.1);
        @include mobile(){
            margin-left: 0;
            margin-top: 44px;
        }
        h5 {
            margin-bottom: 0 ;
            padding: 40px 40px 40px 40px;
            color: #067179;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 140.4%;
            @include mobile(){
                font-size: 18px;
                margin-bottom: 96px;
                padding: 32px 24px;
            }
        }
    }
}

@include mobile(){
    .contactPage{
        
        .ant-col-12{
            max-width: none;
            // padding: 0 16px;
        }
    }
}

.contact {
    margin-bottom: 40px;

    .bannerContainer {
        height: 20vh;
    }

    .map {
        padding: 20px 0 10px;
    }

    .information {
        display: flex;         
        align-items: center;   
        margin-bottom: 15px;

        p, a {
            margin: 0;             
            font-size: 16px;      
        }
        
        p {
            color: #067179;
        }

        a {
            color: #0068ff; 
        }

        i {
            color: #067179;
            margin-right: 10px;    
            font-size: 24px;       
        }
    }

    .icon {
        width: 38px;
        margin-right: 0.7rem;
    }

    .social-media p {
        color: #067179;
    }

    .social-icons {
        display: flex;
        margin-top: 0.5rem;

        a {
            width: 35px;
            height: 35px;
            border-radius: 5px;
            background: linear-gradient(45deg, #1abc9c, #067179);
            color: #fff;
            text-align: center;
            line-height: 35px;
            margin-right: 0.5rem;
            transition: 0.3s;

            &:hover {
                transform: scale(1.05);
            }
        }
    }
}