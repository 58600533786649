@import "../../App.scss";

.bannerContainer {
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  color: white;
  height: 40vh;
  // background-image: url('../../assets/images/layout.png');
  background-size: cover;
  margin-top: 105px;
  @include mobile {
    height: 284px;
  }
  @include tablet{
    margin-top: 60px;
  }

  .bannerContent {
    position: absolute;
    top: 18%;
    left: 120px;
    @include mobile {
      top: 130px;
      left: 17px;
    }
    &__subtitle {
      font-size: 18px;
      font-weight: 400;
      line-height: normal;
      @include mobile {
        font-size: 14px;
        padding-bottom: 8px;
      }

    }
    &__title {
      font-size: 26px;
      font-weight: 700;
      padding-right: 39.25vw;
      line-height: normal;
      color: transparent;
      background-image: linear-gradient(#fff,#fff);
      background-repeat: no-repeat;
      -webkit-background-clip: text;
      background-position: -1500px 0;
      animation: backcolor 4s linear infinite alternate-reverse;
      
      @include mobile {
        padding-right: 40px;
        font-size: 24px;
      }
    }
    &::before{
      border-left: 2px solid red;
    }
    @keyframes backcolor {
        100%{
         
          background-position: 0 0 ;
        }
    }
    &__content {
      margin-top:16px;
      padding-right:700px;
      margin-bottom:32px;
      font-size: 18px;
      @include mobile {
        padding:10px 10px 10px 0px;
        margin-bottom:12px;
        margin-top: 8px;
        font-size: 12px;

      }
    }
  }

  .bannerContent.homeBanner {
    @include mobile {
      top: 30px;
      left: 17px;
    }
  }
}
