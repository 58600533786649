* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  font-family: "Montserrat", sans-serif;
}


.containerCustom {
  padding-right: 120px;
  padding-left: 120px;
  @include mobile {
    padding-right: 16px;
    padding-left: 16px;
  }
}

.sectionContainer {
  padding-top: 120px;
  padding-bottom: 120px;
  @include mobile {
    padding-top: 55px;
  padding-bottom: 55px;
  }
}

.title {
  color: $titleColor;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 24px;
  @include mobile {
    font-size: 24px;
    margin-bottom: 12px;
  }
  &__content {
    color: $greyColor;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    @include mobile {
      font-size: 14px;
    }
  }
}
