@import "../../App.scss";

.careerPage {
  min-height: 800px;
  overflow: hidden;
}
.buttonCareers {
  &__next {
    background-color: $whiteColor;
    color: $mainColor;
    border: 1px solid $mainColor;
    transition: all 0.5s ease;
    &:hover,
    &:focus,
    &:active {
      border: 1px solid $mainColor !important;
      background: $mainColor;
      .careersIcon {
        color: $whiteColor;
      }
    }
  }
  &__prev {
    @extend .buttonCareers__next;
  }
}

.careersIcon {
  color: $mainColor;
}

.careers {
  margin-bottom: 80px;
  margin-top: 24px;
  min-height: 1000px;
  @include mobile {
    margin-bottom: 55px;
  }
  &__filter {
    height: 650px;
    // overflow-y:scroll;
    width: 100%;
    @include mobile() {
      padding: 0 16px;
    }
    .ant-card-head {
      @include mobile() {
        padding: 0 16px;
      }
    }
    .ant-card-body {
      @include mobile() {
        padding: 16px 16px 0 16px;
      }
    }
    .ant-checkbox-wrapper {
      @include mobile() {
        width: 100%;
        padding-bottom: 16px;
      }
    }
  }
  &__pagination {
    text-align: center;
    vertical-align: middle;
    padding-top: 48px;
    @include mobile() {
      padding-top: 10px;
    }
    .ant-pagination{
      display: inline-flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      &-item {
        width: 10px !important;
        margin-right: 0;
        margin-top: 0 !important;
        margin: auto;
        display: inline-flex;
      justify-content: center;
      align-items: center;
        a {
          padding: 0;
          color: #333 !important;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
        &-active {
          a {
            color: #067179 !important;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          }
        }
      }
    }
   
  }
}
.ant-checkbox-wrapper {
  margin-left: 0 !important;
  width: 100%;
}

.filter__button {
  width: 100%;
  height: 44px;
  padding: 12px 0;
  margin-bottom: 24px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  img {
    padding-right: 8px;
    padding-bottom: 2px;
  }
}

.brandList {
  height: 100%;
  max-height: 440px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 5px;
    height: 8px;
    background-color: #e0e0e0; /* or add it to the track */
  }
  &::-webkit-scrollbar-thumb {
    background: #aeaeae;
  }
}

.provinceList {
  display: block;
  height: 100%;
  max-height: 210px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 5px;
    height: 8px;
    background-color: #e0e0e0; /* or add it to the track */
  }
  &::-webkit-scrollbar-thumb {
    background: #aeaeae;
  }
  @include mobile {
    max-height: 400px;
  }
}

.custom-checkbox{
}

.empty_career{
  svg{
    width: 300px;
    height: 300px;
  }
  .ant-empty-image{
    height: 300px;
  }
  .ant-empty-description{
    font-size: 28px;
  }
}

.inp {
  position: relative;
  margin: 20px 0;
  width: 100%;
}

.inp input {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 12px;
  width: 100%;
  outline: none;
  background: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  margin-bottom: 15px;
  font-size: 16px;
  @include tablet {
    width: 70%;
  }
}

.inp input:focus {
  border-color: #067179;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2); 
}

.inp .label {
  position: absolute;
  top: 50%;
  left: 12px;
  font-size: 16px;
  color: #aaa;
  transition: all 0.3s ease;
  transform: translateY(-50%);
  pointer-events: none;
  background: white; 
  padding: 0 4px; 
  z-index: 1;
}

.inp input:focus + .label,
.inp input:not(:placeholder-shown) + .label {
  top: -12px;
  left: 10px;
  font-size: 12px;
  color: #067179;
  z-index: 1; 
}

.inp .focus-bg {
  display: none;
}
