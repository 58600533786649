@import "../../../../App.scss";

.partner {
  position: relative;
  background-color: #F5F5F5;
  overflow: hidden;
}
.carouselPartner {
    padding-left:50px;
    padding-right:50px;
  width: 100%;
  height: 117px;
  vertical-align: middle;
  align-items: center;
  &__cardItem {
    height: 100%;

    & > img {
      margin: 0 auto;
      margin-top: 20px;
      width: 100%;
      height: 80px;
      object-fit: contain;
    }
  }
}

.buttonPartner {
  &__prev {
    background-color: $whiteColor;
    border: 1px solid $mainColor;
    color: $mainColor;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 120px;
    &:focus,
    &:hover {
      background-color: $mainColor;
    }
    @include mobile {
      left: 15px;
    }
  }

  &__next {
    background-color: $whiteColor;
    border: 1px solid $mainColor;
    color: $mainColor;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 120px;
    &:focus,
    &:hover {
      background-color: $mainColor;
    }
    @include mobile {
      right: 15px;
    }
  }
}
