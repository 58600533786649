// MyComponent.scss
@import "../../App.scss";

.cardCustom {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden; 
  border-radius: 8px;

  .lineCard {
    margin-top: 16px;
    background: $lineColor;
    height: 1px;
    width: 100%;
  }

  &__image {
    width: 100%;
    height: 260px;
    overflow: hidden;
    border-radius: 8px;
    position: relative; 
    @include mobile {
      height: 230px;
    }

    .image-link {
      display: block;
      width: 100%;
      height: 100%;
      text-decoration: none;
    }

    .card-image {
      object-fit: cover;
      width: 100%;
      height: 100%;
      transition: transform 0.3s ease;
      
      &:hover {
        transform: scale(1.1);
        cursor: pointer;
      }
    }
  }

  &__title {
    height: 45px;
    // color: $greyColor;
    color: red;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 16px;
    display: -webkit-box;
    -webkit-line-clamp: 2; 
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__content {
    margin-top: 16px;
    margin-bottom: 16px;
    display: -webkit-box;
    -webkit-line-clamp: 2; 
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 45px;
    color: #333;
  }

  &__readMore {
    color: $mainColor;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration-line: underline;
  }
}

.article-card {
  background-color: #fff; 
  color: var(--primaryColor);
  border-radius: 20px;
  overflow: hidden;
  box-shadow: inset 0 0 0 1px #ECECEC;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.article-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); 
}

.article-card-img-wrapper {
  width: 100%;
  padding-top: calc(100% / 2.1);
  position: relative;
  overflow: hidden;
}

.article-card-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.4s ease; 
}

.article-card:hover .article-card-img {
  transform: scale(1.1);
}

.article-card .article-card-content {
  padding: 18px 22px;
  height: 170px;
}

.article-card .article-card-title {
  font-weight: bolder;
  font-size: 16px;
  margin: 14px 0;
  color: black;
}

.article-card .article-card-title a {
  color: black;
  text-decoration: none;
  transition: color 0.3s ease;
}

.article-card-desc {
  font-size: 14px;
}

.article-card-desc .ant-typography {
  margin-bottom: 0;
}

.article-card .ant-badge-count {
  min-width: 20px;
  height: 24px;
  padding: 0 10px;
  color: #fff;
  font-weight: normal;
  font-size: 10px;
  line-height: 24px;
  background: var(--textColorCustom);
  border-radius: 12px;
  box-shadow: 0 0 0 1px #fff;
}

.article-card .ant-skeleton-image.article-card-skeleton-img {
  width: 100%;
  height: 200px;
}

.article-list {
  padding: 10px;
}

.article-list .ant-tabs-top > .ant-tabs-nav::before {
  display: none;
}

.article-slick .ant-carousel .slick-list .slick-slide .news-carousel-item {
  padding: 10px 15px;
  vertical-align: top;
  height: 100%;
}

.article-slick .article-slick-loadmore {
  text-align: center;
}

.article-slick .article-slick-title {
  text-align: left;
  font-weight: bold;
  font-size: 30px;
  line-height: 38px;
  text-align: center;
  margin-bottom: 30px;
}

.article-slick .articel-slick-desc {
  text-align: center;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 24px;
}
