@import "../../../../App.scss";
.ourTeam {
    text-align: center;
    padding-bottom: 127px;
    overflow: hidden;
    @include mobile(){
        padding-bottom: 80px;
    }
    &__title {
        padding-bottom: 24px;
        margin: 0 !important;
        
        @include mobile(){
            padding-bottom: 49px;
        }
    }
    &__desc {
        color: #333;
        // text-align: center;
        text-align: justify;
        // text-align-last: center; 
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        max-width: 700px;
        margin: 0 auto;
        @include mobile(){
            padding: 0 16px;
        }
    }
    &__content {
        display: flex;
        justify-content: center;
        align-items: center;
        @include mobile(){
            display: block !important;
            padding: 0 16px;

        }
        &__image {
            margin-top: 56px;
            max-width: 1024px;
            @include mobile(){
                max-width: none !important;
            }
            .ant-col{
                @include mobile(){
                  
                }   
                img{
                    @include mobile(){
                        width: 100%;
                        margin-bottom: 12px;
                    }   
                }
              
            }
            &--top {
                margin-bottom: 22px;
                
                @include mobile(){
                    margin-bottom: 0px;
                }
            }
        }
    }

}

@include mobile() {
    .ourTeam {
        text-align: center;
        &__content {
            display: flex;
            justify-content: center;
            align-items: center;
            &__image {
                margin-top: 24px;
                max-width: 340px;
                 width: 100%;
                &--top {
                    margin-bottom: 22px;
                }
            }
        }
    }
}
.image-container {
    padding: 0 7px;
}

.ourTeam__desc {
    strong, b {
      font-size: 110%; 
      font-weight: bold;
    }
  }
  