@import "../../App.scss";

.buttonCustom {
  border-radius: 8px;
  background-color: $BgColorButton;
  color: $whiteColor;
  font-weight: 700;
  line-height: normal;
  height: 44px;
  width: 170px;
  outline: none;
  border: none;
  &:hover,
  &:focus,
  &:active {
    border: 1px solid $mainColor !important;
    background: $whiteColor;
    color: $mainColor
  }
}

.buttonCustom.whiteButton {
  @extend .buttonCustom;
  background-color: $whiteColor;
  color: $mainColor;
  &:hover {
    background-color: $greyColor;
    color: $whiteColor;
  }
  &:focus,
  &:active {
    color: $mainColor;
    border: 1px solid $mainColor;
  }


}

.buttonCustom.mainButton {
  @extend .buttonCustom;
  background-color: $mainColor;
  &:hover,
  &:focus,
  &:active {
    border: 1px solid $mainColor !important;
    background: $mainColor;
    color:$whiteColor;
  }
}



.buttonCustom.mainButton {
  @extend .buttonCustom;
  background-color: $mainColor;
  &:hover,
  &:focus,
  &:active {
    border: 1px solid $mainColor !important;
    background: $mainColor;
    color:$whiteColor;
  }

}
.buttonCustom.mainButton.small {
  @extend .buttonCustom;
  background-color: $mainColor;
  &:hover,
  &:focus,
  &:active {
    border: 1px solid $mainColor !important;
    background: $mainColor;
    color:$whiteColor;
  }
  & > span {
    font-size: 14px;
  }
}
