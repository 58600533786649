@import '../../../../App.scss';
.service{
    padding: 80px 0;
}
.ourService {
    &__title {
        text-align: center;
        padding-bottom:64px;
        margin: 0 !important;
        @include mobile {
        padding-bottom:24px;
        }
    }
    &__content{
        .careers__pagination {
            padding-top: 64px;
            @include mobile(){
              padding-top: 26px;
            }
        }
    }
 
}