@import "../../../../../App.scss";
.header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    box-sizing: border-box;
    cursor: pointer ;
    &__top {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        background-color: #032209;
        height: 48px;
        border-bottom: none;

        &--button {
            background-color: $BgColorButton;
            border: none;
            outline: none;
            width: 103px;
            height: 27px;
            border-radius: 4px;
            padding: 0;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            span {
                color: #fff;
                padding: 5 24px;
            
            }
            &:hover{
                background-color:#fff;
                span{
                    color: $mainColor;
                }
            }
            &--res{
                margin-right: -76px;
            }
        }
        &__dropdown {
                margin-left: 24px;
                padding: 0 !important;
                color: #fff;
                &::after {
                    border: none;
                }
            .anticon-caret-down {
                color: #fff;
                padding-top: 8px;
            }
        }
    }
    &__nav {
        background-color: #067179;
        text-transform: uppercase;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 63px;
        &__menu {
            background: none;
            border-bottom: none;
            display: inline-flex;
            vertical-align: middle;
            align-items: center;
            ::before {
                content: none !important;
            }
            li {
                height: 100%;
                padding: 0 32px !important;
                color: #fff;
                font-style: normal;
                font-weight: 700;
                font-size: 18px;
                &:nth-last-child(1) {
                    padding-right: 0 !important;
                }
                &:nth-last-child(6) {
                    padding-left: 0 !important;
                }
            }
        }
    }
    .ant-menu-item {
        a {
            color: #fff;
            font-weight: 700;
            font-size: 18px;
            &:hover {
                color: #f6ae14 !important;
            }
            &:active{
               
            }
        }
        &-selected::after {
            border-bottom: none !important;
        }
        &::after {
            border-bottom: none !important;
        }
    }

    .ant-menu-item-active::after {
        border-bottom: none !important;

    }
    .ant-menu-submenu-active::after {
        border-bottom: none !important;
    }
}
.ant-menu-submenu {
    position: relative; 
    &::after {
        border-bottom: none !important;
    }
    &:hover {
        // color: #f6ae14 !important;
    }
    
    &-title {
        display: flex;
        margin: auto;
        align-items: center;
        flex-direction: row-reverse;
                &:hover{
            color: #F6AE14; 
        }
        a {
            font-size: 18px;
            &:hover{
                color: #F6AE14;
            }
        }
    }
    &-popup {
        position: fixed;
        z-index: 1000;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }
    &-selected::after {
        border-bottom: none;
    }
    .ant-menu-item-selected {
        color: #067179 !important;
    }
    .ant-menu {
        &-item {
            display: flex;
            align-items: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin: 0 !important;
            &-selected {
                color: $mainColor !important;
                background-color: #f2f2f2;
                font-weight: 500;
            }
            &:hover {
                color: $mainColor !important;
                background-color: #f2f2f2;
                font-weight: 500;
            }
            a{
                &:hover{
                    color:$mainColor;
                }
            }
        }
        &-title-content{
            white-space: nowrap;
            overflow: hidden; 
            text-overflow: ellipsis !important;
        }
        
    }
    .ant-menu-vertical{
         margin-top: 10px;
         max-height: 240px;
         overflow: scroll;
         scrollbar-width: thin;
         scrollbar-color: #999999 #f0f0f0;
         width: 390px;
       
    }
   
::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: #999; 
  border-radius: 5px; 
}

::-webkit-scrollbar-thumb:hover {
  background-color: #666;
}

::-webkit-scrollbar-thumb:active {
  background-color: #555;
}

::-webkit-scrollbar-track {
  background-color: #ddd; 
}

::-webkit-scrollbar-track:hover {
  background-color: #ccc;
}
    .anticon{
        svg{
            display: flex;
        }
    }
}
.anticon-caret-down {
    float: right;
    line-height: 32px;
    padding-left: 5px;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected{
    color: #F6AE14;
}

.ant-drawer {
    &-header {
        padding: 11px 16px;
        &-title {
            flex-direction: row-reverse;
            .ant-drawer-close {
                margin: 0;
            }
        }
    }
    &-body {
        padding: 16px 0;
  
        .ant-menu {
            &-item {
                margin: 0;
                margin-bottom: 16px;
                &:hover {
                    background-color: #f2f2f2;
                    border-left: 3px solid $mainColor;
                }
                
                &-selected {
                    background-color: #f2f2f2 !important;
                    border-left: 3px solid $mainColor;
                }
                &::after {
                    border-right: none !important;
                    
                }
                &::before{
                    border-left: 3px solid $mainColor;

                }
                &-selected a {
                    color: $mainColor;
                }
                &-only-child {
                    margin-bottom: 0;
                }
                &:not(:last-child) {
                    margin-bottom: 0 !important;
                }
            }
            &-title {
                &-content {
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    color: #333;
                    &:hover {
                        color: $mainColor;
                    }
                    a {
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
                        color: #333;
                        &:hover {
                            color: $mainColor;
                        }
                    }
                    &::after {
                        border-right: none !important;
                    }
                }
            }
            &-submenu {
                :hover {
                    background-color: #f2f2f2;
                    font-weight: 700;
                    // color: $mainColor !important;
                }
                :active {
                    color: $mainColor;
                }
                &-title {
                    margin: 0;
                    font-size: 14px;
                    font-style: normal;
                    line-height: normal;
                    &-content {
                        a{
                            font-weight: 400 !important;
                        }
                        &:hover {
                            color: $mainColor;
                        }
                    }
                }
                &-arrow{
                    &::before {
                        background-color: $mainColor;
                    }
                    &::after {
                        background-color: $mainColor;
                    }
                }
                .ant-menu{
                    height: 250px;
                    overflow: scroll;
                    scrollbar-width: thin;
                    scrollbar-color: #999999 #f0f0f0;
                    background: none;
                    
                    &-item{
                        .ant-menu-title-content{
                            a{
                                font-weight: 400 !important;
                                &:hover{
                                    font-weight: 600 !important;
                                }
                            }
                        }
                    }
                }
                &-open{
                    .ant-menu{
                        &-item{
                            .ant-menu-title-content{
                                font-weight: 400;
                                &:hover{
                                    font-weight: 600 !important;
                                }
                            }
                        }
                    }
                }
            }
        }
        
    }
}
.ant-menu-light .ant-menu-submenu-title:hover{
    color: #F6AE14;
}

@media (max-width: 1300px) {
    .ant-menu-submenu-title a {
        font-size: 14px ;
    }
    .header .ant-menu-item a {
        font-size: 14px ;
    }
    .header__nav.containerCustom{
        padding-left: 40px ;
        @include mobile {
            padding-left: 20px;
        .anticon.anticon-menu{
            padding-left: 20px;
        }
        }
        &.a {
            padding-right: 12px ;
        }
    }
    .header__nav__menu li {
        padding: 0px 18px !important;
    }
}

.ant-dropdown-menu {
    margin: 0; 
    padding: 0; 
    border-radius: 4px;
}

.ant-dropdown-menu-item {
    position: relative;
    padding: 10px 15px; 
    color: black;
    background-color: transparent; 
    border-radius: 4px; 
    overflow: hidden; 
    transition: background-color 0.3s ease; 
}

.ant-dropdown-menu-item::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(45deg, rgba(6,113,121,255), transparent);
    transition: transform 0.3s ease;
    transform: scale(0);
    transform-origin: center;
}

.ant-dropdown-menu-item:hover::before {
    transform: scale(1);
}