@import "../sass/index.scss";




* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  line-height: 150%;
  font-size: 16px;
}

.newsDetailBody * {
  font-size: unset; 
}

.App {
  width: 100%;
  overflow-x: hidden;
}

.sectionCustom {
  margin-top: 100px;
  margin-bottom: 100px;
}
.containerCustom {
  
  padding-right: 120px;
  padding-left: 120px;
  @include mobile {
    padding-right: 16px;
    padding-left: 16px;
  }
}

.sectionContainer {
  padding-top: 120px;
  padding-bottom: 120px;
  @include mobile {
    padding-top: 56px;
  padding-bottom: 56px;
  }
}

.title {
  color: #15555a !important; 
  font-size: 40px !important;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 24px;
  @include mobile {
    font-size: 28px !important;
    margin-bottom: 12px;
  }
  &__content {
    color: $greyColor;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    @include mobile {
      font-size: 14px;
    }
  }
}

.shine-effect {
  position: relative;
  overflow: hidden;
  border-radius: 12px;

  img {
    object-fit: cover;
    transition: transform 0.5s ease, filter 0.5s ease;
    border-radius: inherit;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  &::before {
    position: absolute;
    top: 0;
    left: -75%;
    z-index: 2;
    display: block;
    content: ''; 
    width: 50%;
    height: 100%;
    background: linear-gradient(
      to right, 
      rgba(255, 255, 255, 0) 0%, 
      rgba(255, 255, 255, 0.6) 100%
    );
    transform: skewX(-25deg);
    transition: all 0.75s ease;
    pointer-events: none;
  }

  &:hover::before {
    animation: shine 0.75s forwards; 
  }

  &:hover img {
    transform: scale(1.1) rotate(2deg);
    filter: brightness(1.1); 
    z-index: 3;
  }

  &:hover {
    transform: translateY(-5px); 
    box-shadow: 0 16px 50px rgba(0, 0, 0, 0.5); 
  }

  @keyframes shine {
    0% {
      left: -75%;
    }
    100% {
      left: 125%; 
    }
  }
}

.no-hover {
  &:hover {
    transform: none; 
    box-shadow: none;
  }

  &:hover img {
    transform: scale(1.1) rotate(2deg);
    filter: brightness(1.1); 
  }
}


h1, h2, h3, h4, h5 {
  margin: 0;
  padding: 0;
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
  font-size: inherit;
}

@mixin white-gradient {
	background: linear-gradient(to right,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
}

$animationSpeed: 85s;

// Animation
@keyframes scroll {
	0% { transform: translateX(0); }
	100% {transform: translateX(calc(-200px * var(--length)))}
}

.slider {
	background: #f5f5f5;
	box-shadow: 0 10px 20px -5px rgba(0, 0, 0, .125);
	height: 120px;
	margin: auto;
	overflow:hidden;
	position: relative;
	width: 100vw;
  display: flex;
  align-items: center;
	
	&::before,
	&::after {
		@include white-gradient;
		content: "";
		height: 100px;
		position: absolute;
		width: 200px;
		z-index: 2;
    @include mobile(){
      width: 60px;
    }
	}
	
	&::after {
		right: 0;
		top: 0;
		transform: rotateZ(180deg);
	}

	&::before {
		left: 0;
		top: 0;
	}
	
	.slide-track {
		animation: scroll $animationSpeed linear infinite;
		display: flex;
		width: calc(250px * var(--length));
	}
	
	.slide {
		height: 80px;
		width: 250px;
    img {
      object-fit: contain;
    }
	}
}