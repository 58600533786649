@import "../../../../App.scss";

.chooseUs {

    @include mobile() {
      height: 100%;
    }
    .chooseUs__carousel {
      .shine-effect {
        height: 100%; 
        max-height: 530px; 
        overflow: hidden;
        transform: translate3d(0, 0, 0);
        will-change: transform;
        @include mobile(){
          max-height: 300px; 
        }
        img {
          transition: transform 0.5s ease-in-out; 
          width: 100%; 
          height: auto;
        }
      }
    }

  &__content {
    &--title {
      padding-bottom: 24px;
      color: #067179;
      font-size: 40px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      @include mobile(){
        font-size: 24px;
        font-weight: 700;
        padding-bottom: 12px;
      }
    }
  }
}

.countCompanies {
  margin-top: 50px;
  @include mobile() {
    margin-top: 32px;
  }
  .count {
    &__title {
      color: $mainColor;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    &__content {
      @include mobile() {
        font-size: 14px;
        width: 88%;
      }
    }
  }
}

.title__content {
  text-align: justify;
  strong, b {
    font-size: 110%; 
    font-weight: bold;
  }
}
