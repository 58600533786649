@import '../../App.scss';

.serviceDetail {
    padding: 50px 20px; 
    background-color: #f9f9f9; 
    border-radius: 8px; 
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
 
    @include mobile() {
        padding: 30px 10px;
    }

    &__title {
        font-size: 16px !important;
        font-weight: bold; 
        color: #273d3d; 
        text-align: center; 
        transition: color 0.3s ease;
        &:hover {
            color: #007bff;
        }
    }

    &__date {
        font-size: 16px !important;
        text-align: center;
        margin-bottom: 12px;
    }

    &__desc {
        font-size: 16px !important;
        text-align: center;
        margin-bottom: 32px;
    }

    &__content {
        font-size: 16px;
        margin-bottom: 40px; 
    }

    .panelJobOffer {
        border: none; 
        margin: 20px 0;

        .panelJobOffer__item {
            background-color: #ffffff; 
            border-radius: 8px; 
            margin-bottom: 16px; 
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); 
            transition: box-shadow 0.3s ease; 

            &:hover {
                box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15); 
            }

            &__header {
                padding: 16px;
                font-weight: bold;
                cursor: pointer;
                transition: background 0.3s ease; 

                &.active {
                    background: #f0f0f0; 
                }
            }
        }
        .panelContent {
            &__image{
                align-items: center;
                display: flex;
                justify-content: center;
                width: 100%;
            }
            &__content{
                padding-top: 12px;
                text-align: justify;
            }
            .panelImage {
                max-width: 70%; 
                border-radius: 12px;
                margin-bottom: 12px; 
                box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); 
            }
        }
    }

}
