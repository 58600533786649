
@import "../../App.scss";
.careersDetail{
    padding: 120px 120px 100px;
    @include mobile() {
        padding: 120px 16px 80px;
    }

    &__title{
        margin-bottom: 4px !important;
        @include mobile(){
            text-align: center;
        }
    }

    &__date{
        margin-bottom: 20px;
        font-style: italic;
        font-size: 16px !important;
        @include mobile(){
            text-align: center;
        }
    }
    &__desc{
            margin-bottom: 24px;
            font-size: 16px !important;
            @include mobile(){
                text-align: center;
                margin-bottom: 40px;
            }
        
    }
    .titleComponent{
        padding:72px 0 50px 4px;
        margin: 0;
    }
    &__image{
        padding:40px 0 48px 0;
        img{
            width: 100%;
           
        }
    }
    .contentDetail {
        margin-top: 80px;
        margin-bottom: 48px;
        
        @include mobile(){
            margin: 37px 0 24px 0;
        }
    }
    // img {
    //     width: 100%;
    //     height: auto;
    // }
   
}
