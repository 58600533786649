@import '../../App.scss';

.search {
    min-height: 800px;
    &__toolbar {
        margin-top:130px;
        @include mobile(){
            margin-top: 12px;
        }
    }
}
