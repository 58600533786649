$mobile-width: 575.98px;
$tablet-width: 991.98px;
$desktop-width: 991.98px;
$large-desktop-width: 1281px;


// Color--------------------------------------------------------------------------------
 
$whiteColor: white;
$greyColor: #333333;
$blackColor: black;
$mainColor: #067179;
$mainButton : #F6AE14;
// Text Color
$titleColor: #063a3d;
// Background
$BgColorButton: #F6AE14;
$footerBgColor: #1b383a;
$bgGrey:#F9F9F9;
$lineColor: #D9D9D9;
// End Color-------------------------------------------------------------------------------
 

