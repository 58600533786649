@import "../../App.scss";

.careersItem {
  padding: 32px 32px 32px 27px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 100%;
  a{
    width: 100%;
  }
  &__button {
    margin-top: 16px;
    .customButton{
      font-weight: 500 !important;
    }
  }
  .careersItemLeft {
    .careersItemLeft__name {
      color: $greyColor;
      margin-bottom: 12px;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      display: -webkit-box;
      -webkit-line-clamp: 1; 
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .careersItemLeft__position {
      color: $greyColor;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      display: -webkit-box;
      -webkit-line-clamp: 1; 
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      @include mobile(){
    
        -webkit-line-clamp: 1; 
       
        margin-bottom: 12px;
      }
      &__custom{
        margin-bottom: 12px;
      }
    }
  }
  .careersItemRight {
    float: right;
    display: flex;
    flex-direction: column;
    align-items: flex-end; 
    position: relative;
    @include mobile {
      align-items: flex-start;
      float: none;
    }

    .careersItemRight__location {
      margin-bottom: 12px;
      padding-top: 10px;
    }
    .careersItemRight__date {
    }
    .careersItemRight__new{
      top: -45%; 
      right: 20%;
      @include tablet{
        top: -120%;
      }
      @include mobile{
        right: -8%;
        top: -175%;
      }
      background-color: #067179;
      color: white;
      padding: 5px 10px;
      font-size: 14px;
      border-radius: 4px;
      font-weight: bold;
      animation: scaleUp 1s infinite; 
      position: absolute; 
    }
  }
}

.jobApplyModal {
  width: 586px;
  height: 606px;
  @include mobile(){
    padding: 0 8px;
  }
  &__title {
    margin-bottom: 30px;
    text-align: center;
    color: #042d0d;

    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .formApplyJob {
    padding: 26px 56px 41px 56px;
    @include mobile(){
      padding: 0;
    }
    &__label {
      margin-bottom: 12px;
    }
    &__input {
      height: 36px;
      width: 100%;
    }
    &__upload {
      width: 100%;
    }
    &__submit {
      margin-top: 40px;
      height: 48px;
      width: 100%;
      background-color: $mainColor;
      color: $whiteColor;
    }
    &--career {
      display: flex;
      p {
        padding-left: 10px;
      }
    }
  }
}

@keyframes scaleUp {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
}
