@import "../../../App.scss";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap");
.formFeedback {
    padding-bottom: 160px;
    @include mobile(){
        padding-bottom: 0;
        flex-direction: column;
        padding-right: 0;   
    }
    &__title {
        padding: 20px 0 39px 0;
        margin: 0 !important;
        @include mobile(){
            text-align: center;
            padding: 48px 0;
            margin: 0 !important;
        }
    }
    &--item{
        @include mobile(){
            flex-direction: column;
        }
    }
    label {
        color: #000;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding-bottom: 12px;
    }
    .ant-form-item {
        &-label {
            padding: 0;
        }
    }
    .ant-input {
        border-radius: 2px;
        &:hover{
            border-color: #067179;
        }
        
    }

    
    .ant-form-item-control {
        margin-left: 0;
        button{
            border: none;
            border-radius: 8px;
        background: #067179;
        margin-top: 8px;
        width: 170px;
        height: 44px;
        span{
       
        }
    }
    }
     .ant-btn-primary{
        padding: 12px 24px;
    }
    // .ant-col-12{
    //     @include mobile(){
    //         padding: 0 !important;
    //     }
    // }
}


