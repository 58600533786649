@import "../../../../App.scss";

.news {
  .title {
    text-align: center;
    &__content {
      // text-align: center;
      text-align: justify;
      text-align-last: center; 
      padding-left: 265px;
      padding-right: 265px;
      @include tablet {
        padding-left: 15px;
        padding-right: 15px;
        font-size: 16px;
      }
    }
  }
  &__content {
    margin-top:55px
  }  
  &__button {
    margin-top:48px;
    text-align: center;
  }
  .cardCustom{
      margin-bottom: 20px;
  }
}
.article-slick.wrapper{
  padding-top: 20px;
}
