.breadcrumb {
  width: 100%;
  padding-top: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ant-breadcrumb {
  ul,
  ol {
    list-style: none;
    display: flex;
  }
  a {
    color: #828282;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  span {
    text-transform: capitalize;
    color: #828282;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
