@import "../../../../App.scss";

.search__button {
  background-color: $mainColor;
  border: 1px solid $mainColor;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: $whiteColor;
  height: 48px;
  width: 100%;
  @include mobile(){
   display: flex;
   justify-content: center;
   width: 50%;
   align-items: center;
   margin-top: 8px;
  }
  &:focus,
  &:active {
    border: 1px solid $mainColor;
  }
  &:hover {
    color: $mainColor;
    border: 1px solid $mainColor;
  }
}

.noData {
    position: absolute;
    top:50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.search__row{
  @include mobile(){
    display: block;
  }
  .ant-input-affix-wrapper {
    height: 48px;
    border: 1px solid #828282;
  }
  .ant-select-selector{
    height: 48px !important;
    border: 1px solid #828282 !important;
  }
  .ant-select-selection-item{
    line-height: 48px !important;
  }
}
.search__col__button{
    display: flex;
    justify-content: center;
}
.ant-select-arrow .anticon > svg{
  vertical-align: middle;
}
.ant-form-item{
  @include mobile(){
    margin-bottom: 8px;
  }
}