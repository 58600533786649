@import "../../App.scss";


.ourServiceSection {
  background-color: $bgGrey;
  border-radius: 8px;
  @include mobile{
    height: 100%;
  }
}
.jobOfferSection{
  padding-bottom: 66px !important;
  @include mobile(){
    padding-top: 64px !important;
    padding-bottom: 56px !important;
  }
}
.bannerContent__content{
  // padding: 0 15px 0 0 !important;
  @include mobile(){
    padding: 0 15px 0 0 !important;
  }
}