@import "../../../../App.scss";

.footer {
  background-color: $footerBgColor;
  color: $whiteColor;
  padding-top: 56px;
  padding-bottom: 24px;
  &__img{
    max-width: none;
    // margin-top: 22px;
    display: flex;                /* Sử dụng Flexbox */
    flex-direction: column;       /* Căn chỉnh các phần tử theo chiều dọc */
    align-items: center; 
    position: fixed;
    bottom: 15%;
    @include mobile {
      bottom: 22%;
    }
    right: 0%;
    padding-right: 12px;
    z-index: 999;
    &--icon{
      border: 10px solid rgba(22, 120, 173, 0.1);
      border-radius: 50%;
      @keyframes scaleAnimation {
        0% {
          transform: scale(0.9);
        }
        50% {
          transform: scale(1.1);
        }
        100% {
          transform: scale(0.9);
        }
      }
      animation: scaleAnimation 2.5s infinite;
      &--layout{
        border-radius: 50%;
         background: #0080C7;
      }
    }
    &--call{
     
      
      padding: 12px;
      transition: transform 0.3s, box-shadow 0.3s;
      @keyframes shakeAnimation {
        0%, 100% {
          transform: translateX(0) rotate(0deg);
        }
        10%, 30%, 50%, 70%, 90% {
          transform: translateX(-5px) rotate(-10deg);
        }
        20%, 40%, 60%, 80% {
          transform: translateX(5px) rotate(10deg);
        }
      }
      
      animation: shakeAnimation 1s infinite;
    }
  }
 
}

.address {
  max-width: 600px;
  margin-top: 32px;
  opacity: 0.8;
  @include mobile {
    margin-bottom: 40px;
  }
}

.listLink {
 
  @include mobile {
    padding-left: 0px;
  }
  &__item {
    a{
      display: flex;
      // justify-content: center;
      @include mobile(){
        justify-content:initial;
      }
    }
    
    img{
      padding-right: 8px;
    }
  }
}

.line {
  margin-top: 63px;
  margin-bottom: 16px;
  height: 1px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.25);
}

.privacy {
  opacity: 0.8;
  @include mobile {
    text-align: center;
    font-size: 14px;
    margin-bottom: 12px;
  }
}
.copyright {
  opacity: 0.8;
  float: right;
  @include mobile {
    float: none;
    text-align: center;
    font-size: 14px;
  }
}

.zalo-chat-widget{  
  right: 20px!important; 
  bottom: 70px!important;  
  @media(max-height: 900px) {
    bottom: 30px!important;  
  }
}  