@import "../../App.scss";
.newsDetail  {
    padding: 100px 0;
    @include mobile() {
        padding: 49px 0 72px 0;
    }
    &__title {
        margin-bottom: 4px !important;
        @include mobile() {
            text-align: center;
        }
    }
    &__time{
        margin-bottom: 12px;
        font-size: 16px !important;
        font-style: italic;
        @include mobile() {
            text-align: center;
        }
    }
    &__date {
        margin-bottom: 34px;
        font-size: 16px !important;
        text-align: justify;
        @include mobile() {
            text-align: center;
        }
    }

    &__morenews {
        padding-top: 128px;
        margin-bottom: 24px;
        font-size: 24px;
        color: #273d3d !important;

        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        @include mobile() {
            text-align: center;
        }
    }
    &__desc{
        text-align: justify;
        img{
            @include tablet {
               width: 100%;
            }
        }
    }
}

  