@mixin flex($justify-content, $align-item) {
  display: flex;
  justify-content: $justify-content;
  align-items: $align-item;
}

@mixin tablet {
  @media only screen and (max-width: $tablet-width) {
    @content;
  }
}

@mixin mobile {
  @media only screen and (max-width: $mobile-width) {
    @content;
  }
}

@mixin desktop {
  @media only screen and (min-width: $tablet-width) and (max-width: $desktop-width) {
    @content;
  }
}

@mixin large-desktop {
  @media only screen and (min-width: $desktop-width) and (max-width: $large-desktop-width) {
    @content;
  }
}

@mixin custom-breakpoint($min-width, $max-width) {
  @media only screen and (min-width: $min-width) and (max-width: $max-width) {
    @content;
  }
}
