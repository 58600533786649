.source-app {
  .button {
    height: 4.6rem;
    border-radius: 0.4rem;
    font-weight: bold;
    font-size: 1.5rem;
  }
}

.carouselCustom .slick-list {
  margin: 0 -15px;
}
.carouselCustom .slick-slide > div {
  padding: 0 15px;
}

.carouselPartner .slick-list {
  margin: 0 -15px;
}
.carouselPartner .slick-slide > div {
  padding: 0 15px;
}

// home page
.ant-collapse-item.panelJobOffer__item
  + .ant-collapse-item.panelJobOffer__item {
  border-top: 1px solid #bdbdbd;
}

.jobApplyModal {
  .ant-modal-content {
    border-radius: 16px;
  }
}

// Custom Pagination Careers

.careers {
  &__pagination {
    li.ant-pagination-item {
      border: none;
      margin-top: 5px;
    }
    .ant-pagination-item a {
      border: none !important; /* Remove border */
      color: $mainColor;
    }
    .ant-pagination-item-active a {
      border: none; /* Remove border */
      color: $mainColor;
      text-decoration: underline;
    }
    .button.ant-pagination-item-link {
      border: 1px solid $mainColor;
      border-radius: 50%;
    }
    .ant-btn-primary[disabled],
    .ant-btn-primary[disabled]:hover,
    .ant-btn-primary[disabled]:focus,
    .ant-btn-primary[disabled]:active {
      border: 1px solid $mainColor;
      .careersIcon {
        color: $mainColor;
      }
    }
  }
}

// Check Box

.custom-checkbox:hover .ant-checkbox-inner,
.custom-checkbox .ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: $mainColor;
}

/* Change the background color when checked */
.custom-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: $mainColor;
}

/* Change the border color when checked */
.custom-checkbox .ant-checkbox-checked .ant-checkbox-inner,
.custom-checkbox .ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: $mainColor;
}
