@import "../../../../App.scss";

.ourService {
   &--title{
    margin: 0 !important;
   }
  .buttonOurService {
    float: right;
    &__next {
      background-color: $whiteColor;
      color: $mainColor;
      border: 1px solid $mainColor;
      &:hover {
        color: $whiteColor;
        border: 1px solid $mainColor;
        background-color: $mainColor;
      }
    }
    &__prev {
      @extend .buttonOurService__next;
      margin-right: 32px;
    }
  }
  .cardItem {
    flex-shrink: 1;
    position: relative;
    height: 314px;
    border-radius: 8px;
    overflow: hidden;

    
    @include mobile {
        height: 380px;
    }
    & > img {
      filter: brightness(0.45);
      width: 100%;
      height: 100%;
      border-radius: 8px;
    }

    &__content {
      position: absolute;
      width: 100%;
      text-align: center;
  
      bottom: 51px;
      color: $whiteColor;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      display: -webkit-box;
    -webkit-line-clamp: 1; 
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    @include mobile(){
       font-size: 24.155px;
    }
    }
  }
}

.carouselCustom {
  height: 314px;
  width: 100%;
  @include mobile(){
    height: 100%;
    margin-bottom: 16px;
  }
  .cardItem {
    position: relative;
    overflow: hidden;
    transform: translate3d(0, 0, 0);
    will-change: transform;

    img {
      width: 100%;
      height: 100%;
      transition: transform 0.3s ease, filter 0.3s ease;
    }

    &:hover {
      img {
        transform: scale(1.1); 
        filter: brightness(0.85);
      }
    }
  }
}


.carouselCustom .slick-slide > div{
  padding: 0 10.5px !important;
  @include mobile(){
    padding: 0 16px !important;
  }
}

