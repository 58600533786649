@import "../../../../App.scss";
.aboutus{
   padding-top: 100px;
   padding-bottom: 177px;
   @include mobile(){
    padding: 0;
   }
}
.aboutUs {
    @include mobile(){
        flex-direction: column-reverse;
        padding: 0;
        margin-bottom: 37px;
    }
    .shine-effect {
        position: relative;
        overflow: hidden; 
        max-height: 430px;
        img {
          @include mobile() {
            margin-top: 0;
            width: 100%;
          }
        }
    }
    &__top{
        @include mobile(){
            margin-bottom: 0px;
        } 
    }
    &__title {
        padding-bottom: 24px;
        margin: 0 !important;
        max-width: 550px;
        @include mobile(){
            padding: 64px 0 32px 0;
            text-align: center;  
        }
    }
    &__desc {
        color: #333; 
        // font-size: 18px;
        font-style: normal;
        // font-weight: 400;
        line-height: normal;
        text-align: justify;
        @include mobile(){
            font-size: 16px;
            text-align: center;
        }
    }
}

@include mobile(){
        .ant-col-12{
            max-width: none;
        }
}

.aboutUs__desc {
    strong, b {
      font-size: 110%; 
      font-weight: bold;
    }
  }
  

